<template>
  <b-modal
    v-model="modalUpdate"
    no-fade
    size="xl"
    ref="edit-user"
    :no-close-on-backdrop="true"
    hide-footer
    hide-header
    hide-backdrop
  >
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-4 col-md-auto">
                <h4 class="card-title mb-4">Editar Proveedor</h4>
              </div>
              <div class="col-2 col-md-auto">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="name">Nombre</label>
                  <input v-model="payload.name" type="text" class="form-control" id="name" placeholder="Nombre">
                  <div v-if="errors.hasOwnProperty('name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.name[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="phone">Phone</label>
                  <input v-model="payload.phone" type="text" class="form-control" id="phone" placeholder="Teléfono">
                  <div v-if="errors.hasOwnProperty('phone')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.phone[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="email">Email</label>
                  <input v-model="payload.email" type="email" class="form-control" id="email" placeholder="Email">
                  <div v-if="errors.hasOwnProperty('email')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.email[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="address">Dirección</label>
                <textarea v-model="payload.address" name="address" id="address" cols="30" rows="5" class="form-control"></textarea>
                <div v-if="errors.hasOwnProperty('address')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.address[0]}}</small>
                </div>
              </div>
              <div class="form-group">
                <label for="address">Notas</label>
                <textarea v-model="payload.notes" name="notes" id="notes" cols="30" rows="5" class="form-control"></textarea>
                <div v-if="errors.hasOwnProperty('notes')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.address[0]}}</small>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-12 d-flex justify-content-between">
                  <div class="col-auto">
                    <button @click.prevent="removeProvider" class="btn btn-gradient-danger btn-md">Eliminar</button>
                  </div>
                  <div class="col-auto">
                    <button @click.prevent="reset" class="btn btn-outline-dark btn-md">Cancelar</button>
                    <button @click.prevent="updateProvider" class="btn btn-gradient-info btn-md ml-3">Actualizar</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  name: 'UpdateProvider',
  data () {
    return {
      errors: {}
    }
  },
  methods: {
    ...mapActions({
      update: 'Provider/update',
      remove: 'Provider/delete'
    }),
    ...mapMutations({
      TOGGLE_MODAL_UPDATE: 'Provider/TOGGLE_MODAL_UPDATE'
    }),
    updateProvider () {
      this.update(this.payload)
        .then(() => {
          this.reset()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    removeProvider () {
      this.remove(this.payload)
        .then(() => {
          this.reset()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    reset () {
      this.errors = {}
      this.$events.fire('refresh-table')
      this.TOGGLE_MODAL_UPDATE(false)
    }
  },
  computed: {
    ...mapGetters('Provider', ['modalUpdate'])
  },
  beforeDestroy () {
    this.$events.off('table-item-edit')
  }
}
</script>
