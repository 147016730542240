<template>
  <b-modal
    v-model="modalOpen"
    no-fade ref="create-client"
    :no-close-on-backdrop="true"
    hide-footer hide-header hide-backdrop
    size="xl"
  >
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Agregar Proveedor</h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="first_name">Nombre</label>
                  <input v-model="form.name" type="text" class="form-control" id="name" placeholder="Nombre">
                  <div v-if="errors.hasOwnProperty('name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.name[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="phone">Teléfono</label>
                  <input v-model="form.phone" type="text" class="form-control" id="phone" placeholder="Teléfono">
                  <div v-if="errors.hasOwnProperty('phone')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.phone[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="email">Email</label>
                  <input v-model="form.email" type="email" class="form-control" id="email" placeholder="Email">
                  <div v-if="errors.hasOwnProperty('email')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.email[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="address">Dirección</label>
                <textarea v-model="form.address" name="address" id="address" cols="30" rows="5" class="form-control"></textarea>
                <div v-if="errors.hasOwnProperty('address')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.address[0]}}</small>
                </div>
              </div>
              <div class="form-group">
                <label for="address">Notas</label>
                <textarea v-model="form.notes" name="notes" id="notes" cols="30" rows="5" class="form-control"></textarea>
                <div v-if="errors.hasOwnProperty('notes')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.notes[0]}}</small>
                </div>
              </div>
              <div class="row justify-content-end">
                  <button @click.prevent="reset" class="btn btn-outline-dark btn-md">Cancelar</button>
                  <button @click.prevent="createProvider" class="btn btn-gradient-info btn-md ml-3">Guardar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'CreateProvider',
  data () {
    return {
      form: {
        name: null,
        phone: null,
        email: null,
        address: null,
        notes: null
      },
      errors: {}
    }
  },
  methods: {
    ...mapActions({
      create: 'Provider/create'
    }),
    ...mapMutations('Provider', ['TOGGLE_MODAL_CREATE']),
    createProvider () {
      this.create(this.form)
        .then(() => {
          this.reset()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    reset () {
      this.errors = {}
      this.$events.fire('refresh-table')
      this.form = {
        name: '',
        phone: '',
        email: '',
        address: '',
        notes: ''
      }
      this.TOGGLE_MODAL_CREATE(false)
    }
  },
  computed: {
    ...mapGetters('Provider', ['modalCreate']),
    modalOpen () {
      return this.modalCreate
    }
  }
}
</script>
